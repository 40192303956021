import $ from 'jquery'

let origin="";
if(location.href.search('localhost')==-1){
    origin="http://deepbluenet.com/";
}else{
    origin="http://localhost/deepblueserver/";
}

function postLoginerUserPost(entity,callback){
    $.ajax({
        type:"post",
        url:origin+"crud/api/insert.php",
        dataType:"json",
        data:{action:"post_loginer_user_post",entity},
        success:function(res){
            callback(res);
        },
        error:function(err){
            console.log(err);
            alert("提交失败");
        }
    });
}

function postMsg(entity,callback){
    $.ajax({
        type:"post",
        url:origin+"crud/api/insert.php",
        dataType:"json",
        data:{action:"db_add_message",entity},
        success:function(res){
            callback(res);
        },
        error:function(err){
            console.log(err);
            alert("提交失败");
        }
    });
}

export {postMsg,postLoginerUserPost}
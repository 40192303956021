<template>
  <div class="user-post">
    <div class="up-unit">
      <h3>{{modes[mode].title}}</h3>
      <div>
        <p class="field" v-for="(value,key) in form" :key="key">
          <label>{{key}} :</label>
          <input v-if="!isWritable(key)" readonly type="input" :name="key" :value="value"/>
          <input v-else type="input" :name="key" v-model="form[key]"/>
        </p>
        <p><button @click="post">提交</button></p>
      </div>
    </div>
  </div> 
</template>
<script>
import { postLoginerUserPost } from '@/utils/http.js'
export default {
  data(){
    return {
      mode:this.$route.query.mode || 'mac',
      form:{
        up_platform:this.$route.query.platform,
        up_user:this.$route.query.user,
        up_timestamp:this.$route.query.timestamp,
        up_mode:this.$route.query.mode,
        up_value:'',
      },
      modes:{
        mac:{
          title:'提交验证码',
          
        },
        qrc:{
          title:'扫码登录确认',
        }
      } 
    }
  },
  created(){
    // set post value for qrc mode
    if(this.mode=='qrc'){
      this.form.up_value = this.$route.query.status || 500; // 200 - login success; 500 - failure
    }
  },
  methods:{
    isWritable(key){
      return this.mode=='mac' && key=='up_value';
    },
    post(){
      if(this.$route.query.status==200 && !this.form.up_value){
        let field = this.mode=='mac'?'验证码':'登录状态';
        alert(field+'未填写!');
        return;
      }
      const entity = this.form;
      postLoginerUserPost(entity,function(response){
        if(response.code==200){
          alert('提交成功!');
        }else{
          alert('提交失败!');
        }
      })
    }
  }
}
</script>
<style scoped lang="less">
.user-post{
  width:100%;
  margin:100px auto;
  max-width: 300px;
  h3{
    text-align: center;
    margin-bottom:50px;
    font-size:16px;
  }
  p.field{
    border-bottom: 1px solid #999;
  }
  p{
    display: flex;
    justify-content: space-between;
    label{
      line-height: 40px;
      width:8rem;
    }
    input{
      border:none;
      padding-left:2em;
      line-height: 40px;
      flex:1;
    }
    button{
      line-height: 35px;
      background:#07c;
      color:white;
      border-radius: 2px;
      width:100%;
      margin-top:50px;
      border:none;
    }
  }
}
</style>